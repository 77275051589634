import clsx from 'clsx';
import { graphql } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import { Link, Trans } from 'gatsby-plugin-react-i18next';
import { find } from 'lodash';
import * as React from 'react';
import { Seo } from '../components/Seo';
import Layout from '../layouts/layout';

import * as classes from './404.module.scss';

const NotFoundPage = props => {
  return <Layout location={props.location}>
    <div className={clsx(classes.NotFoundPage, 'container p-0')}>
      <h2>Page not found</h2>
      <div className={classes.ImageContainer}>
        <StaticImage src="../images/aida-nothing-found.jpg" alt="Nothing has been found" width={400} height={203} />
      </div>
      <p>
        <Trans>Sorry 😔, we couldn't find what you were looking for</Trans>
      </p>
      <p><Link to="/"><Trans>Navigate to the homepage</Trans></Link></p>
    </div>
  </Layout>;
};

export default NotFoundPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
        filter: {ns: {in: ["common", "404-page"]}, language: {eq: $language}}
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export const Head = ({ data }) => {
  const edge = find(data.locales.edges, [ 'node.ns', '404-page' ]);
  let obj = undefined;
  if (!!edge) {
    obj = JSON.parse(edge.node.data);
  }
  const title = !!obj ? obj.title : 'Not found';

  return <Seo title={title} description={!!obj ? obj.description : null} />;
};

